.header {
  background-color: #000;
}

.header__wrapper {
  height: 100%;
  align-items: center;
  padding-top: 10px;
  display: flex;
}

.header__logo {
  height: 58px;
  padding-top: 12px;
  display: block;
}

.header__menu-btn {
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #0000;
  background-image: url("menu.27acc91b.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  margin-left: auto;
  font-size: 0;
}

.header__menu-btn.close {
  background-image: url("close.01365e67.svg");
}

.header__menu-list {
  height: 0;
  padding: 8px;
  transition: height .5s;
  overflow: hidden;
}

.header__menu-item {
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  padding: 9px 0;
  font-size: 21px;
}

.header__menu-item:last-child {
  border-bottom: none;
}

.header__menu-item:hover > .header__menu-link {
  color: #ee400d;
}

.menu-show {
  height: 215px;
}

@media (min-width: 1300px) {
  .header {
    width: 100%;
    background-color: #0000;
    position: relative;
  }

  .header__logo {
    height: 72px;
    box-sizing: border-box;
  }

  .header__menu-btn {
    display: none;
  }

  .header__menu-list {
    width: 100%;
    max-width: 1300px;
    box-sizing: border-box;
    height: inherit;
    justify-content: flex-end;
    padding: 0 40px;
    display: flex;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .header__menu-item {
    border: none;
    padding: 25px 0;
  }

  .header__menu-item:not(:last-child) {
    margin-right: 20px;
  }
}

.hero {
  width: 100%;
  height: 590px;
  color: #fff;
  background-color: #000;
  background-image: linear-gradient(#000 -10%, #0000 15%), url("hero-bg.7fb2a388.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero__contacts {
  text-align: right;
  flex-direction: column;
  padding-top: 20px;
  font-size: 18px;
  display: flex;
}

.hero__title {
  width: 300px;
  text-align: center;
  margin: 20px auto 0;
  font-size: 42px;
}

.hero__btn {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background: #ee400d linear-gradient(to top, #ba350d, #ee400d);
  border: none;
  border-radius: 5px;
  margin: 70px auto 0;
  padding: 8px 0;
  font-family: PT Sans Narrow, Arial, sans-serif;
  font-size: 19px;
  display: block;
  box-shadow: 3px 3px 5px #0a132cb3;
}

.hero__btn:hover {
  background: #b63109;
}

.hero__btn:active {
  color: #000;
  background: #dc7b5e;
}

.hero__social {
  width: 39px;
  height: 39px;
  background-image: url("instagram.7f85763c.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10px;
  margin-left: auto;
  font-size: 0;
}

.hero__sup {
  font-size: 23px;
}

.hero__description {
  text-align: center;
  margin-top: 5px;
  display: block;
}

@media (min-width: 768px) {
  .hero__btn {
    width: 60%;
  }
}

@media (min-width: 1300px) {
  .hero {
    height: 760px;
    background-image: linear-gradient(to right, #000 0%, #0000 40%), url("hero-bg.7fb2a388.jpg");
    background-position: 100%;
    background-size: 60% 100%;
    background-clip: border-box;
    margin-top: -82px;
  }

  .hero__title {
    width: 509px;
    margin: -10px 0 0 30px;
    font-size: 56px;
  }

  .hero__contacts {
    padding-top: 100px;
    font-size: 24px;
  }

  .hero__btn {
    width: 300px;
    margin: 75px 130px 0;
  }

  .hero__description {
    width: 560px;
  }
}

.benefits__list {
  margin: 10px 0;
}

.benefits__item {
  padding: 20px 0 20px 75px;
  font-size: 28px;
  position: relative;
}

.benefits__item:not(:last-child) {
  border-bottom: 2px solid #eee;
}

.benefits__item:before {
  content: "";
  width: 60px;
  height: 60px;
  background-color: #ee400d;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.benefits__item:after {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.benefits__item--calendar:after {
  width: 40px;
  height: 40px;
  background-image: url("calendar.820d4344.svg");
  top: 25px;
  left: 10px;
}

.benefits__item--worker:after {
  width: 60px;
  height: 60px;
  background-image: url("worker.0970becf.svg");
  top: 15px;
  left: 0;
}

.benefits__item--award:after {
  width: 55px;
  height: 55px;
  background-image: url("award.e7e24bb7.svg");
  top: 16px;
  left: 3px;
}

.benefits__item--tag:after {
  width: 55px;
  height: 55px;
  background-image: url("tag.4a844f47.svg");
  top: 18px;
  left: 10px;
}

@media (min-width: 1300px) {
  .benefits {
    width: 50%;
    max-width: 650px;
    padding: 30px 0 40px;
    position: relative;
    left: 50%;
    transform: translateX(-100%);
  }

  .benefits > .container {
    margin: 0;
    padding: 0 20px 0 40px;
  }

  .benefits__item {
    font-size: 32px;
  }
}

.about {
  color: #fff;
  background-color: #000;
  padding-bottom: 20px;
}

.about__text {
  text-align: justify;
  padding-top: 20px;
  font-size: 21px;
}

.about__btn {
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  color: #000;
  cursor: pointer;
  background: linear-gradient(to top, #ccc, #fff);
  border: none;
  border-radius: 5px;
  margin: 20px auto 0;
  padding: 10px 0;
  font-family: PT Sans Narrow, Arial, sans-serif;
  font-size: 19px;
  display: block;
}

.about__btn:hover {
  background: #b63109;
}

.about__btn:active {
  color: #000;
  background: #dc7b5e;
}

@media (min-width: 768px) {
  .about__btn {
    width: 60%;
  }
}

@media (min-width: 1300px) {
  .about {
    width: 50%;
    max-width: 850px;
    color: #000;
    background-color: #fff;
    padding: 10px 0 40px;
    position: relative;
    right: -50%;
    transform: translateX(-100%);
  }

  .about > .container {
    flex-direction: column;
    margin: 0;
    padding: 0 40px 0 20px;
    display: flex;
  }

  .about__text {
    padding: 0;
    font-size: 28px;
  }

  .about__btn {
    color: #fff;
    background: linear-gradient(to top, #cf3f13, #ee400d);
    margin-top: auto;
    box-shadow: 3px 3px 5px #ccc;
  }
}

.services {
  color: #fff;
  background-color: #ee400d;
  padding: 20px 0;
}

.services__title {
  text-align: center;
  margin: 0 0 20px;
  font-size: 29px;
}

.services__item {
  color: #000;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 20px 0;
  font-size: 24px;
  box-shadow: 0 0 25px -5px #223c5063;
}

.services__item-title {
  color: #ee400d;
  text-transform: uppercase;
  margin: 0 20px 0 90px;
  position: relative;
}

.services__item-title:before {
  content: "";
  width: 50px;
  height: 50px;
  background-color: #ee400d;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: -70px;
  transform: translateY(-50%);
}

.services__item-title:after {
  content: "";
  width: 40px;
  height: 50px;
  background-image: url("check.9ecca677.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  top: 50%;
  left: -55px;
  transform: translateY(-50%);
}

.services__item-text {
  text-align: justify;
  margin: 0;
  padding: 15px 20px 0;
  font-size: 18px;
}

@media (min-width: 768px) {
  .services {
    width: 100%;
  }

  .services__list {
    flex-wrap: wrap;
    display: flex;
  }

  .services__item {
    flex-basis: 49%;
    margin-bottom: 24px;
    margin-right: 2%;
  }

  .services__item-title {
    font-size: 28px;
  }

  .services__item:nth-child(2n) {
    margin-right: 0;
  }
}

@media (min-width: 1300px) {
  .services {
    padding: 40px 0;
  }

  .services__title {
    margin-bottom: 40px;
    font-size: 36px;
  }
}

.ceilings {
  background-color: #eee;
  padding: 20px 0;
}

.ceilings > .container {
  margin: 0;
  padding: 0;
}

.ceilings__title {
  text-align: center;
  margin: 0 0 20px;
  font-size: 29px;
}

.ceilings__slider {
  display: flex;
  overflow: auto;
}

.ceilings__slider::-webkit-scrollbar {
  height: 0;
}

.ceilings__items {
  display: flex;
  position: relative;
}

.ceilings__item {
  width: 320px;
  min-height: 490px;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin: 0 10px;
  padding: 10px 20px;
}

.ceilings__item-title {
  text-align: center;
  margin: 5px 0 10px;
  font-size: 21px;
}

.ceilings__item-text {
  text-align: justify;
  font-size: 18px;
}

.ceilings__img {
  width: 100%;
  height: 207px;
}

.ceilings__btn {
  display: none;
}

@media (min-width: 1300px) {
  .ceilings {
    width: 100%;
    padding: 40px 0;
  }

  .ceilings > .container {
    margin: 0 auto;
    padding: 0 40px;
  }

  .ceilings__title {
    margin-bottom: 40px;
    font-size: 36px;
  }

  .ceilings__slider {
    flex-wrap: wrap;
  }

  .ceilings__items {
    height: 495px;
    flex-wrap: wrap;
    display: flex;
    overflow: hidden;
  }

  .ceilings__items-show {
    height: inherit;
  }

  .ceilings__items-show + .ceilings__btn {
    display: none;
  }

  .ceilings__item {
    width: 31%;
    margin: 0 21px 20px;
  }

  .ceilings__item:first-child, .ceilings__item:nth-child(3n+1) {
    margin-left: 0;
  }

  .ceilings__item:nth-child(3n) {
    margin-right: 0;
  }

  .ceilings__item-title {
    margin: 10px 0 20px;
    font-size: 28px;
  }

  .ceilings__btn {
    width: 300px;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(to top, #cf3f13, #ee400d);
    border: none;
    border-radius: 5px;
    margin: 40px auto 0;
    padding: 10px 0;
    font-size: 18px;
    display: block;
    box-shadow: 3px 3px 5px #ccc;
  }

  .ceilings__btn:hover {
    background: #b63109;
  }

  .ceilings__btn:active {
    color: #000;
    background: #dc7b5e;
  }
}

.order {
  padding: 20px 0;
}

.order__title {
  text-align: center;
  margin: 0 0 20px;
  font-size: 29px;
}

.order__list {
  font-size: 19px;
}

.order__item {
  min-height: 180px;
  background-color: #eee;
  border-radius: 5px;
  margin-top: 75px;
  padding: 20px;
}

.order__item-title {
  color: #ee400d;
  text-align: center;
  margin-bottom: 5px;
  font-size: 28px;
  position: relative;
}

.order__item-title:before {
  content: "";
  width: 90px;
  height: 90px;
  background-color: #ee400d;
  border: 5px solid #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
}

.order__item-title:after {
  content: "";
  width: 80px;
  height: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  top: -105px;
  left: 50%;
  transform: translateX(calc(10px - 50%));
}

.order__item-title--one:after {
  background-image: url("one.5eef75d6.svg");
}

.order__item-title--two:after {
  background-image: url("two.d08777c9.svg");
}

.order__item-title--three:after {
  background-image: url("three.3ba4ec8f.svg");
}

.order__item-title--four:after {
  background-image: url("four.e44cadef.svg");
}

.order__item-title--five:after {
  background-image: url("five.847d4144.svg");
}

.order__item-title--done:after {
  width: 72px;
  height: 67px;
  background-image: url("check.9ecca677.svg");
  top: -89px;
}

.order__item-text {
  text-align: justify;
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .order__title {
    margin: 0;
  }

  .order__list {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    display: flex;
  }

  .order__item {
    width: 49%;
    box-sizing: border-box;
    min-height: 250px;
    position: relative;
  }
}

@media (min-width: 1300px) {
  .order {
    width: 100%;
    padding: 40px 0;
  }

  .order__title {
    margin-bottom: 0;
    font-size: 36px;
  }

  .order__item {
    width: 31%;
    box-sizing: border-box;
    min-height: 330px;
    margin-left: 21px;
    margin-right: 21px;
  }

  .order__item:first-child, .order__item:nth-child(3n+1) {
    margin-left: 0;
  }

  .order__item:nth-child(3n) {
    margin-right: 0;
  }
}

.works {
  background-color: #eee;
  padding: 20px 0 60px;
}

.works > .container {
  margin: 0;
  padding: 0;
}

.works__title {
  text-align: center;
  margin: 0 0 20px;
  font-size: 29px;
}

.works__slider {
  display: flex;
  overflow: auto;
}

.works__slider::-webkit-scrollbar {
  height: 0;
}

.works__items {
  display: flex;
  position: relative;
}

.works__item {
  width: 330px;
  height: 260px;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin: 0 10px;
  padding: 10px;
}

.works__item-title {
  text-align: center;
  margin: 5px 0 10px;
  font-size: 21px;
}

.works__item-text {
  text-align: justify;
  font-size: 18px;
}

.works__img {
  width: 100%;
  height: 100%;
}

.works__btn {
  display: none;
}

@media (min-width: 1300px) {
  .works {
    width: 100%;
    padding: 40px 0;
  }

  .works > .container {
    margin: 0 auto;
    padding: 0 40px;
  }

  .works__title {
    margin-bottom: 40px;
    font-size: 36px;
  }

  .works__slider {
    flex-wrap: wrap;
  }

  .works__items {
    height: 545px;
    flex-wrap: wrap;
    display: flex;
    overflow: hidden;
  }

  .works__items-show {
    height: inherit;
  }

  .works__items-show + .works__btn {
    display: none;
  }

  .works__item {
    width: 31%;
    margin: 0 21px 20px;
  }

  .works__item:first-child, .works__item:nth-child(3n+1) {
    margin-left: 0;
  }

  .works__item:nth-child(3n) {
    margin-right: 0;
  }

  .works__item-title {
    margin: 10px 0 20px;
    font-size: 28px;
  }

  .works__btn {
    width: 300px;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(to top, #cf3f13, #ee400d);
    border: none;
    border-radius: 5px;
    margin: 40px auto 0;
    padding: 10px 0;
    font-size: 18px;
    display: block;
    box-shadow: 3px 3px 5px #ccc;
  }

  .works__btn:hover {
    background: #b63109;
  }

  .works__btn:active {
    color: #000;
    background: #dc7b5e;
  }
}

.form {
  height: 540px;
  color: #fff;
  background-color: #000;
  background-image: linear-gradient(to top, #000, #0000), url("form-bg.00a128cc.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 30px 0 65px;
}

.form__title {
  text-align: center;
  margin: 0 0 20px;
  font-size: 29px;
}

.form__description {
  text-align: center;
  font-size: 19px;
}

.form__fields {
  flex-direction: column;
  display: flex;
}

.form__input {
  border: none;
  border-radius: 5px;
  outline: 1px solid #ccc;
  margin-bottom: 12px;
  padding: 9px;
  font-size: 19px;
}

.form__input:focus {
  outline: 1px solid #ee400d;
}

.form__comment {
  resize: none;
  border: none;
  border-radius: 5px;
  outline: 1px solid #ccc;
  padding: 9px;
  font-size: 19px;
}

.form__comment:focus {
  outline: 1px solid #ee400d;
}

.form__btn {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(to top, #ba350d, #ee400d);
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 8px 0;
  font-family: PT Sans Narrow, Arial, sans-serif;
  font-size: 19px;
  display: block;
}

.form__btn:hover {
  background: #b63109;
}

.form__btn:active {
  color: #000;
  background: #dc7b5e;
}

.form__checkbox-label {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
}

.form__checkbox-link {
  color: #ee400d;
  cursor: pointer;
}

.form__back-label {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 19px;
  display: block;
}

.form__phone-label {
  text-align: center;
  font-size: 28px;
  display: block;
}

.form__phone-label a {
  color: #ee400d;
}

@media (min-width: 768px) {
  .form__fields {
    width: 60%;
    margin: 0 auto;
  }
}

@media (min-width: 1300px) {
  .form {
    width: 100%;
    height: 570px;
    background-image: linear-gradient(to right, #0000 -60%, #000 0%, #0000 60%, #000 100%), url("form-bg.00a128cc.jpg");
    background-position: center;
    background-size: 60% 100%;
    background-clip: border-box;
    padding: 40px 0 65px;
  }

  .form__title {
    font-size: 36px;
  }

  .form__description {
    margin-top: 50px;
  }

  .form__fields {
    width: 500px;
    margin: 10px auto;
  }

  .form__input {
    margin-bottom: 15px;
  }
}

.map {
  overflow: hidden;
}

.map iframe {
  width: 100%;
}

@media (min-width: 1300px) {
  .map {
    width: 100%;
  }

  .map iframe {
    height: 900px;
  }
}

.contacts {
  color: #fff;
  text-align: center;
  background-color: #000;
  flex-direction: column;
  padding-bottom: 30px;
  display: flex;
}

.contacts__address-title {
  margin: 10px 0 5px;
  font-size: 24px;
}

.contacts__address {
  color: #ee400d;
  font-size: 26px;
}

.contacts__clock {
  margin: 5px 0;
}

.contacts__logo {
  display: none;
}

.contacts__social {
  width: 39px;
  height: 39px;
  background-image: url("instagram.7f85763c.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 20px auto auto;
  font-size: 0;
}

@media (min-width: 1300px) {
  .contacts {
    width: 100%;
    position: relative;
  }

  .contacts__address-title, .contacts__address, .contacts__clock, .contacts__email {
    position: absolute;
    left: 50%;
    transform: translateX(calc(50% - 755px));
  }

  .contacts__address-title {
    color: #000;
    width: 250px;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 20px 20px 5px;
    top: -470px;
  }

  .contacts__address {
    width: 250px;
    color: #000;
    background-color: #fff;
    padding: 0 20px;
    font-size: 24px;
    top: -410px;
  }

  .contacts__clock {
    color: #000;
    width: 250px;
    background-color: #fff;
    padding: 10px 20px;
    top: -386px;
  }

  .contacts__email {
    color: #000;
    width: 250px;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    padding: 10px 20px 20px;
    top: -350px;
  }

  .contacts__logo {
    width: 250px;
    margin: 40px auto 0;
    display: block;
  }
}

.privacy {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  font-size: 18px;
}

.privacy > .header {
  background-color: #000;
  padding-bottom: 20px;
}

.privacy .header__menu-list, .privacy .header__menu-btn {
  display: none;
}

.privacy .container {
  height: 100%;
}

.privacy__backLink {
  text-transform: uppercase;
  color: #fff;
  width: 200px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(to top, #ba350d, #ee400d);
  border-radius: 5px;
  margin: 30px auto 0;
  padding: 10px;
  font-size: 19px;
  display: block;
}

.privacy__backLink:hover {
  background: #b63109;
}

.privacy__backLink:active {
  color: #000;
  background: #dc7b5e;
}

.privacy h2 {
  color: #ee400d;
  font-size: 28px;
}

.privacy__link {
  color: #ee400d;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: PT Sans Narrow, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

a {
  color: #fff;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

.container {
  box-sizing: border-box;
  height: 100%;
  margin: 0 19px;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media (min-width: 768px) {
  .container {
    margin: 0 30px;
  }
}

@media (min-width: 1300px) {
  .wrapper {
    flex-wrap: wrap;
    display: flex;
  }

  .container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 40px;
  }
}

/*# sourceMappingURL=index.4da95d3e.css.map */
