@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px;
  box-sizing: border-box;
}

a {
  color: $accent-white-color;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

.container {
  box-sizing: border-box;
  margin: 0 19px;
  height: 100%;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

@media (min-width: $tablet-width) {
  .container {
    margin: 0 30px;
  }
}

@media (min-width: $desktop-width) {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .container {
    max-width: $desktop-width;
    margin: 0 auto;
    padding: 0 40px;
  }
}

