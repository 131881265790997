.about {
  background-color: $accent-black-color;
  color: $accent-white-color;
  padding-bottom: 20px;

  &__text {
    padding-top: 20px;
    font-size: 21px;
    text-align: justify;
  }

  &__btn {
    display: block;
    padding: 10px 0;
    font-family: "PT Sans Narrow", Arial, sans-serif;
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 19px;
    text-transform: uppercase;
    width: 100%;
    margin: 20px auto 0;
    background: linear-gradient(to top, #cccccc, $accent-white-color);
    color: $accent-black-color;
    cursor: pointer;

    &:hover {
      background: $hover-orange-color;
    }

    &:active {
      background: $active-orange-color;
      color: $accent-black-color;
    }
  }
}

@media (min-width: $tablet-width) {
  .about {
    &__btn {
      width: 60%;
    }
  }
}

@media (min-width: $desktop-width) {
  .about {
    position: relative;
    right: -50%;
    transform: translateX(-100%);
    width: 50%;
    max-width: 850px;
    padding: 10px 0 40px;
    color: $accent-black-color;
    background-color: $accent-white-color;

    & > .container {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0 40px 0 20px;
    }

    &__text {
      font-size: 28px;
      padding: 0;
    }

    &__btn {
      margin-top: auto;
      background: linear-gradient(to top,#cf3f13, $accent-orange-color);
      box-shadow: 3px 3px 5px #cccccc;
      color: $accent-white-color;
    }
  }
}
