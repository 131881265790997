@import "../../variables.scss";

.privacy {
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding-bottom: 40px;

  & > .header {
    padding-bottom: 20px;
    background-color: $accent-black-color;
  }

  & .header__menu-list,
  & .header__menu-btn {
    display: none;
  }

  & .container {
    height: 100%;
  }

  &__backLink {
    display: block;
    background: linear-gradient(to top,#ba350d, $accent-orange-color);
    font-size: 19px;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 10px;
    color: $accent-white-color;
    width: 200px;
    text-align: center;
    margin: 30px auto 0;
    cursor: pointer;

    &:hover {
      background: $hover-orange-color;
    }

    &:active {
      background: $active-orange-color;
      color: $accent-black-color;
    }
  }

  h2 {
    color: $accent-orange-color;
    font-size: 28px;
  }

  &__link {
    color: $accent-orange-color;
  }
}
