@import "../../variables.scss";

.header {
  background-color: $accent-black-color;

  &__wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 10px;
  }

  &__logo {
    display: block;
    height: 58px;
    padding-top: 12px;
  }

  &__menu-btn {
    margin-left: auto;
    background-color: transparent;
    background-image: url("../../../static/img/menu.svg");
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    font-size: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;

    &.close {
      background-image: url("../../../static/icon/close.svg");
    }
  }

  &__menu-list {
    height: 0;
    overflow: hidden;
    padding: 8px;
    transition: height .5s ease;
  }

  &__menu-item {
    font-size: 21px;
    padding: 9px 0;
    text-align: center;
    border-bottom: 1px solid $accent-grey-color;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover > .header__menu-link{
      color: $accent-orange-color;
    }
  }
}

.menu-show {
  height: 215px;
}

@media (min-width: $desktop-width) {
  .header {
    background-color: transparent;
    position: relative;
    width: 100%;

    &__logo {
      height: 72px;
      box-sizing: border-box;
    }

    &__menu-btn {
      display: none;
    }

    &__menu-list {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1300px;
      padding: 0 40px;
      box-sizing: border-box;
      height: inherit;
    }

    &__menu-item {
      border: none;
      padding: 25px 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
