.hero {
  width: 100%;
  height: 590px;
  background-image:
    linear-gradient(to bottom, #000000 -10%, transparent 15%),
    url("../../../static/img/hero-bg.jpg");
  background-color: $accent-black-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: $accent-white-color;

  &__contacts {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-top: 20px;
  }

  &__title {
    font-size: 42px;
    width: 300px;
    text-align: center;
    margin: 20px auto 0;
  }

  &__btn {
    display: block;
    padding: 8px 0;
    text-align: center;
    font-family: "PT Sans Narrow", Arial, sans-serif;
    border: none;
    font-size: 19px;
    border-radius: 5px;
    text-transform: uppercase;
    margin: 70px auto 0;
    background: linear-gradient(to top,#ba350d, $accent-orange-color);
    background-color: $accent-orange-color;
    color: $accent-white-color;
    box-shadow: 3px 3px 5px #0a132cb3;
    cursor: pointer;

    &:hover {
      background: $hover-orange-color;
    }

    &:active {
      background: $active-orange-color;
      color: $accent-black-color;
    }
  }

  &__social {
    font-size: 0;
    width: 39px;
    height: 39px;
    background-image: url("../../../static/icon/instagram.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 10px;
    margin-left: auto;
  }

  &__sup {
    font-size: 23px;
  }

  &__description {
    display: block;
    text-align: center;
    margin-top: 5px;
  }
}

@media (min-width: $tablet-width) {
  .hero {
    &__btn {
      width: 60%;
    }
  }
}

@media (min-width: $desktop-width) {
  .hero {
    background-image:
      linear-gradient(to right, #000000 0%, transparent 40%),
      url("../../../static/img/hero-bg.jpg");
    background-position: right center;
    background-clip: border-box;
    background-size: 60% 100%;
    height: 760px;
    margin-top: -82px;

    &__title {
      margin: 0;
      margin-left: 30px;
      margin-top: -10px;
      width: 509px;
      font-size: 56px;
    }

    &__contacts {
      padding-top: 100px;
      font-size: 24px;
    }

    &__btn {
      width: 300px;
      margin: 75px 130px 0;
    }

    &__description {
      width: 560px;
    }
  }
}
