$accent-black-color: #000000;
$accent-orange-color: #ee400dff;
$accent-white-color: #ffffff;
$accent-grey-color: #eeeeee;

$hover-orange-color: #b63109;

$active-orange-color: #dc7b5e;

$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 1300px;
