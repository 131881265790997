.benefits {
  &__list {
    margin: 10px 0;
  }

  &__item {
    position: relative;
    font-size: 28px;
    padding: 20px 0;
    padding-left: 75px;

    &:not(:last-child) {
      border-bottom: 2px solid $accent-grey-color;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      background-color: $accent-orange-color;
    }

    &::after {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--calendar {
      &::after {
        top: 25px;
        left: 10px;
        width: 40px;
        height: 40px;
        background-image: url("../../../static/img/calendar.svg");
      }
    }

    &--worker {
      &::after {
        top: 15px;
        left: 0;
        width: 60px;
        height: 60px;
        background-image: url("../../../static/img/worker.svg");
      }
    }

    &--award {
      &::after {
        top: 16px;
        left: 3px;
        width: 55px;
        height: 55px;
        background-image: url("../../../static/img/award.svg");
      }
    }

    &--tag {
      &::after {
        top: 18px;
        left: 10px;
        width: 55px;
        height: 55px;
        background-image: url("../../../static/img/tag.svg");
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .benefits {
    position: relative;
    left: 50%;
    transform: translateX(-100%);
    width: 50%;
    max-width: 650px;
    padding: 30px 0 40px;

    & > .container {
      margin: 0;
      padding: 0 20px 0 40px;
    }

    &__item {
      font-size: 32px;
    }
  }
}
