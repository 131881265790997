.contacts {
  display: flex;
  flex-direction: column;
  background-color: $accent-black-color;
  color: $accent-white-color;
  text-align: center;
  padding-bottom: 30px;

  &__address-title {
    font-size: 24px;
    margin: 10px 0 5px;
  }

  &__address {
    font-size: 26px;
    color: $accent-orange-color;
  }

  &__clock {
    margin: 5px 0;
  }

  &__logo {
    display: none;
  }

  &__social {
    font-size: 0;
    width: 39px;
    height: 39px;
    background-image: url("../../../static/icon/instagram.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 10px;
    margin: auto;
    margin-top: 20px;
  }
}

@media (min-width: $desktop-width) {
  .contacts {
    position: relative;
    width: 100%;

    &__address-title,
    &__address,
    &__clock,
    &__email {
      position: absolute;
      left: 50%;
      transform: translateX(calc(50% - 755px));
    }

    &__address-title {
      top: -470px;
      color: $accent-black-color;
      background-color: $accent-white-color;
      padding: 20px 20px 5px;
      border-radius: 5px 5px 0 0;
      width: 250px;
    }

    &__address {
      top: -410px;
      background-color: $accent-white-color;
      width: 250px;
      padding: 0 20px;
      font-size: 24px;
      color: $accent-black-color;
    }

    &__clock {
      top: -386px;
      color: $accent-black-color;
      background-color: $accent-white-color;
      padding: 10px 20px 10px;
      width: 250px;
    }

    &__email {
      top: -350px;
      color: $accent-black-color;
      background-color: $accent-white-color;
      padding: 10px 20px 20px;
      border-radius: 0 0 5px 5px;
      width: 250px;
    }

    &__logo {
      display: block;
      width: 250px;
      margin: 40px auto 0;
    }
  }
}
