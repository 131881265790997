.order {
  padding: 20px 0;

  &__title {
    font-size: 29px;
    margin: 0 0 20px;
    text-align: center;
  }

  &__list {
    font-size: 19px;
  }

  &__item {
    background-color: $accent-grey-color;
    padding: 20px;
    margin-top: 75px;
    min-height: 180px;
    border-radius: 5px;

    &-title {
      position: relative;
      font-size: 28px;
      margin-bottom: 5px;
      color: $accent-orange-color;
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 90px;
        height: 90px;
        background-color: $accent-orange-color;
        border: 5px solid #fff;
        border-radius: 50%;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: -105px;
        left: 50%;
        transform: translateX(calc(-50% + 10px));
        width: 80px;
        height: 80px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      &--one {
        &::after {
          background-image: url("../../../static/icon/one.svg");
        }
      }

      &--two {
        &::after {
          background-image: url("../../../static/icon/two.svg");
        }
      }

      &--three {
        &::after {
          background-image: url("../../../static/icon/three.svg");
        }
      }

      &--four {
        &::after {
          background-image: url("../../../static/icon/four.svg");
        }
      }

      &--five {
        &::after {
          background-image: url("../../../static/icon/five.svg");
        }
      }

      &--done {
        &::after {
          width: 72px;
          height: 67px;
          top: -89px;
          background-image: url("../../../static/icon/check.svg");
        }
      }
    }

    &-text {
      margin-top: 0;
      margin-bottom: 0;
      text-align: justify;
    }
  }
}

@media (min-width: $tablet-width) {
  .order {
    &__title {
      margin: 0;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__item {
      position: relative;
      width: 49%;
      box-sizing: border-box;
      min-height: 250px;
    }
  }
}

@media (min-width: $desktop-width) {
  .order {
    width: 100%;
    padding: 40px 0;

    &__title {
      font-size: 36px;
      margin-bottom: 0;
    }

    &__item {
      width: 31%;
      margin-left: 21px;
      margin-right: 21px;
      box-sizing: border-box;
      min-height: 330px;

      &:first-child,
      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
