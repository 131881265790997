.form {
  height: 540px;
  padding: 30px 0 65px;
  background-image:
    linear-gradient(to top, #000000, transparent),
    url("../../../static/img/form-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-color: $accent-black-color;
  color: $accent-white-color;

  &__title {
    font-size: 29px;
    margin: 0 0 20px;
    text-align: center;
  }

  &__description {
    font-size: 19px;
    text-align: center;
  }

  &__fields {
    display: flex;
    flex-direction: column;
  }

  &__input {
    font-size: 19px;
    padding: 9px;
    margin-bottom: 12px;
    border: none;
    outline: 1px solid #cccccc;
    border-radius: 5px;

    &:focus {
      outline: 1px solid $accent-orange-color
    }
  }

  &__comment {
    border-radius: 5px;
    font-size: 19px;
    padding: 9px;
    border: none;
    outline: 1px solid #cccccc;
    resize: none;

    &:focus {
      outline: 1px solid $accent-orange-color
    }
  }

  &__btn {
    display: block;
    padding: 8px 0;
    margin-top: 20px;
    font-family: "PT Sans Narrow", Arial, sans-serif;
    border: none;
    border-radius: 5px;
    font-size: 19px;
    text-transform: uppercase;
    background: linear-gradient(to top,#ba350d, $accent-orange-color);
    color: $accent-white-color;
    cursor: pointer;

    &:hover {
      background: $hover-orange-color;
    }

    &:active {
      background: $active-orange-color;
      color: $accent-black-color;
    }
  }

  &__checkbox {
    &-label {
      font-size: 18px;
      margin-top: 10px;
      text-align: center;
    }

    &-link {
      color: $accent-orange-color;
      cursor: pointer;
    }
  }

  &__back-label {
    display: block;
    font-size: 19px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  &__phone-label {
    display: block;
    font-size: 28px;
    text-align: center;

    & a {
      color: $accent-orange-color;
    }
  }
}

@media (min-width: $tablet-width) {
  .form {

    &__fields {
      width: 60%;
      margin: 0 auto;
    }
  }
}

@media (min-width: $desktop-width) {
  .form {
    padding: 40px 0 65px;
    width: 100%;
    height: 570px;
    background-image: linear-gradient(to right, (transparent -60%, #000000 0%), (transparent 60%, #000000 100%)),
                      url("../../../static/img/form-bg.jpg");
    background-position: center;
    background-clip: border-box;
    background-size: 60% 100%;

    &__title {
      font-size: 36px;
    }

    &__description {
      margin-top: 50px;
    }

    &__fields {
      width: 500px;
      margin: 10px auto;
    }

    &__input {
      margin-bottom: 15px;
    }
  }
}
