.services {
  background-color: $accent-orange-color;
  color: $accent-white-color;
  padding: 20px 0;

  &__title {
    font-size: 29px;
    margin: 0 0 20px;
    text-align: center;
  }

  &__item {
    background-color: $accent-grey-color;
    color: $accent-black-color;
    font-size: 24px;
    padding: 20px 0;
    border-radius: 5px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 0px 25px -5px rgba(34, 60, 80, 0.39);
    -moz-box-shadow: 0px 0px 25px -5px rgba(34, 60, 80, 0.39);
    box-shadow: 0px 0px 25px -5px rgba(34, 60, 80, 0.39);

    &-title {
      position: relative;
      color: $accent-orange-color;
      margin: 0 20px;
      margin-left: 90px;
      text-transform: uppercase;

      &::before {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: -70px;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: $accent-orange-color;
      }

      &::after {
        content: "";
        display: block;
        width: 40px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: -55px;
        transform: translateY(-50%);
        background-image: url("../../../static/icon/check.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &-text {
      padding: 15px 20px 0;
      text-align: justify;
      font-size: 18px;
      margin: 0;
    }
  }
}

@media (min-width: $tablet-width) {
  .services {
    width: 100%;

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      flex-basis: 49%;
      margin-right: 2%;
      margin-bottom: 24px;

      &-title {
        font-size: 28px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .services {
    padding: 40px 0;

    &__title {
      font-size: 36px;
      margin-bottom: 40px;
    }
  }
}
