.ceilings {
  background-color: $accent-grey-color;
  padding: 20px 0;

  & > .container {
    margin: 0;
    padding: 0;
  }

  &__title {
    font-size: 29px;
    margin: 0 0 20px;
    text-align: center;
  }

  &__slider {
    display: flex;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__items {
    display: flex;
    position: relative;
  }

  &__item {
    width: 320px;
    min-height: 490px;
    background-color: $accent-white-color;
    border: 2px solid #dddddd;
    margin: 0 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 5px;

    &-title {
      margin: 5px 0 10px;
      font-size: 21px;
      text-align: center;
    }

    &-text {
      text-align: justify;
      font-size: 18px;
    }
  }

  &__img {
    width: 100%;
    height: 207px;
  }

  &__btn {
    display: none;
  }
}

@media (min-width: $desktop-width) {
  .ceilings {
    width: 100%;
    padding: 40px 0;

    & > .container {
      margin: 0 auto;
      padding: 0 40px;
    }

    &__title {
      font-size: 36px;
      margin-bottom: 40px;
    }

    &__slider {
      flex-wrap: wrap;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      height: 495px;
      overflow: hidden;
    }

    &__items-show {
      height: inherit;

      & + .ceilings__btn {
        display: none;
      }
    }

    &__item {
      width: 31%;
      margin: 0 21px;
      margin-bottom: 20px;

      &:first-child,
      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &-title {
        font-size: 28px;
        margin: 10px 0 20px;
      }
    }

    &__btn {
      display: block;
      width: 300px;
      border: none;
      border-radius: 5px;
      background-color: $accent-orange-color;
      color: $accent-white-color;
      font-size: 18px;
      padding: 10px 0;
      margin: 40px auto 0;
      background: linear-gradient(to top,#cf3f13, $accent-orange-color);
      box-shadow: 3px 3px 5px #cccccc;
      cursor: pointer;

      &:hover {
        background: $hover-orange-color;
      }

      &:active {
        background: $active-orange-color;
        color: $accent-black-color;
      }
    }
  }
}
