.map {
  overflow: hidden;

  & iframe {
    width: 100%;
  }
}

@media (min-width: $desktop-width) {
  .map {
    width: 100%;

    & iframe {
      height: 900px;
    }
  }
}
