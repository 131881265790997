@import "../../variables.scss";
@import "./styles/hero.scss";
@import "./styles/benefits.scss";
@import "./styles/about.scss";
@import "./styles/services.scss";
@import "./styles/ceilings.scss";
@import "./styles/order.scss";
@import "./styles/works.scss";
@import "./styles/form.scss";
@import "./styles/map.scss";
@import "./styles/contacts.scss";
